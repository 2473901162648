<template>
    <div class="customs-card__documents">
        <div class="flex justify-between items-center flex-wrap">
            <span class="customs-card__title no-wrap">
                Attached documents
            </span>
            <label class="customs-card__documents-search-label" style="background: #F7F7F7; padding: 10px; border-radius: 12px;">
                <svg-importer icon-name="icons/search" style="margin-right: 8px;" />
                <input
                    type="text"
                    v-model="search"
                    placeholder="Search for (press enter to search)"
                    style="background: rgb(247, 247, 247);padding: 0;border: 0;min-height: 22px;min-width: 220px"
                >
            </label>
        </div>
        <div class="customs-card__documents-table">
            <div class="flex">
                <div v-for="column in documentsColumns" :key="column.id" class="customs-card__documents-th">
                    <button v-if="column.sortable" class="th-sort" @click="toggleSort(column.id)">
                        <svg-importer v-if="sort.key === column.id && sort.order === 1" :key="`${column.id}_up`" icon-name="icons/table-sort-up" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === -1" :key="`${column.id}_down`" icon-name="icons/table-sort-down" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === 0 || sort.key !== column.id" :key="`${column.id}_default`" icon-name="icons/table-sort" />
                    </button>

                    <div>
                        {{ column.name }}
                    </div>
                </div>
            </div>
            <div v-for="document in documentsFiltered" :key="document.name" class="customs-card__documents-tr">
                <div
                    v-for="column in documentsColumns"
                    :key="column.name"
                    class="customs-card__documents-td"
                    :class="{ 'flex-grow-1': !column.name }"
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                    <div v-if="!column.name && documentsFiltered.length">
                        <div class="flex items-center ml-2 justify-end" style="gap: 24px;">
                            <a :href="document.path" target="_blank">
                                <fa :icon="['fal', 'arrow-to-bottom']" />
                            </a>
                        </div>
                    </div>
                    <a v-else-if="column.id === 'name'" :href="document.path" target="_blank">
                        {{ document[column.id] }}
                    </a>
                    <span v-else>
                        {{ document[column.id] }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            documentsData: this.documents,
            documentsColumns: [
                { id: 'name', name: 'Name', width: '' , sortable: true},
                { id: 'type', name: 'Type', width: '', sortable: true },
                { id: 'date', name: 'Date', width: '', sortable: true },
                { id: '', name: '', sortable: false },
            ],
            sort: {
                key: '',
                order: 0
            },
            search: '',
        };
    },

    computed: {
        customsList() {
            return this.$store.getters['customs/list'];
        },
        currentData() {
            return this.customsList.find(item => item.id == this.$route.params.id);
        },
        documents() {
            return this.currentData?.documents || [];
        },
        documentsFiltered() {
            return this.documents.filter(doc => doc.name.indexOf(this.search) !== -1).sort(this.doSort);
        },
    },

    methods: {
        doSort(a, b) {
            if (!this.sort.order) {
                return 0; 
            }

            if (this.sort.order === 1) {
                return a[this.sort.key] > b[this.sort.key] ? 1 : -1;
            }

            return a[this.sort.key] > b[this.sort.key] ? -1 : 1;
        },
        updateSort(colId) {
            if (colId !== this.sort.key) {
                this.sort.key = colId;
                this.sort.order = 1;

                return;
            }

            if (this.sort.order === 1) {
                this.sort.order = -1;

                return;
            }

            if (this.sort.order === -1) {
                this.sort.order = 0;

                return;
            }

            this.sort.order = 1;
        },
        toggleSort(colId) {
            this.updateSort(colId);
            this.documentsData = [...this.currentData.documents].sort(this.doSort);
        },
    },
};
</script>
<style lang="scss">
.customs-card__documents-search-label {
    margin-top: 12px;
    display: flex;
    align-items: center;
    svg {
        circle {
            stroke: #6A7884;
        }
    }
}

.customs-card__documents-table {
    margin-top: 12px;
}

.customs-card__documents-th {
    padding-left: 24px;
    position: relative;
    display: flex;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 22px;
        transform: translateY(-50%);
        background-color: #B1BFCA;
    }

    &:first-child,
    &:last-child {
        padding-left: 0;
        border-left: none;

        &:before {
            content: none;
        }
    }

    &:first-child {
        width: 30%;
    }

    &:nth-child(2) {
        width: 100px;
    }
}

.customs-card__documents-tr {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
}

.customs-card__documents-td {
    padding-left: 24px;

    &:first-child,
    &:last-child {
        padding-left: 0;
    }

    &:first-child {
        width: 30%;
    }

    &:nth-child(2) {
        width: 100px;
    }
}
</style>