<template>
    <div class="sh-d-progress__item">
        <div class="flex flex__grow relative pl-8">
            <div class="sh-d-circle">
                <div class="sh-d-circle__circle">
                    <span></span>
                </div>
                <div class="sh-d-circle__line"></div>

            </div>
            <div class="w__100-p">
                <div class="sh-d-progress__content">
                    <div class="sh-d-progress__head z-10 relative">
                        {{ status }}
                    </div>
                </div>
                <div class="sh-d-progress__body">
                    <div class="realtive">
                        <div class="sh-d-progress__group-container">
                            {{ date }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        status: String,
        date: String,
    },
};
</script>
<style lang="scss" scoped>
.sh-d-progress__head {
    margin-bottom: 7px;
    padding-bottom: 0;
    font-weight: 700;
}
.sh-d-progress__group-container {
    margin-bottom: -7px;
    padding-left: 16px;

    color: #6A7884;
}

.sh-d-progress__item {
    padding-left: 0;
}
</style>