<template>
    <div class="customs-card">
        <div>
            <div style="color: #6A7884;">
                Declaration detailed view
            </div>

            <div class="text-header-primary">
                Custom 6241546524: DONE

            </div>
        </div>
        <div class="customs-card__columns">
            <Payments />
            <div class="flex flex-grow-1" style="gap: 16px;">
                <Documents class="w__50-p" />
                <Goods class="w__50-p" />
            </div>
        </div>
    </div>
</template>
<script>
import Payments from '@/components/customs/payments.vue';
import Documents from '@/components/customs/documents.vue';
import Goods from '@/components/customs/goods.vue';

export default {
    components: {
        Payments,
        Documents,
        Goods,
    },

    data() {
        return {

        };
    },

    computed: {
        customsList() {
            return this.$store.getters['customs/list'];
        },
        currentData() {
            return this.customsList.find(item => item.id == this.$route.params.id);
        },
    },
}

</script>
<style lang="scss">
@import '@/styles/components/_shipment-progress.scss';
@import '@/styles/components/_shipment-progress-group.scss';
@import '@/styles/components/_shipment-timeline.scss';
@import '@/styles/components/_shipment-progress-elements.scss';

.customs-card__columns {
    margin-top: 32px;
    display: flex;
    gap: 16px;
}

.customs-card__payments,
.customs-card__documents,
.customs-card__goods {
    background: #fff;
    border-radius: 16px;
    padding: 16px;
}

.customs-card__delimeter {
    height: 2px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    background: #F7F7F7;
}

.customs-card__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}

.customs-card__payments {
    &-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 16px;
    }
    &-item {
        display: flex;
        flex-direction: column;
        width: calc(50% - 6px);

        span:first-child {
            color: #6A7884;
        }
    }
}
</style>