<template>
    <div class="customs-card__goods">
        <span class="customs-card__title">
            Goods
        </span>

        <div class="customs-goods-table">
            <div class="flex">
                <div v-for="column in columns" :key="column.id" class="customs-card__goods-th">
                    <button v-if="column.sortable" class="th-sort" @click="toggleSort(column.id)">
                        <svg-importer v-if="sort.key === column.id && sort.order === 1" :key="`${column.id}_up`" icon-name="icons/table-sort-up" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === -1" :key="`${column.id}_down`" icon-name="icons/table-sort-down" />
                        <svg-importer v-else-if="sort.key === column.id && sort.order === 0 || sort.key !== column.id" :key="`${column.id}_default`" icon-name="icons/table-sort" />
                    </button>

                    <div>
                        {{ column.name }}
                    </div>
                </div>
            </div>
            <div v-for="good in (goodsData)" :key="good.id" class="customs-card__goods-tr">
                <div v-for="column in columns" :key="`${good.id}_${column.name}`" class="customs-card__goods-td">
                    {{ good[column.id] }}
                    <span v-if="column.id === 'weight'">
                        kg
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sort: {
                key: '',
                order: 0,
            },
            columns: [
                { id: 'name', name: 'Name', width: '' , sortable: false },
                { id: 'weight', name: 'Weight', width: '', sortable: true },
            ],
        };
    },

    computed: {
        customsList() {
            return this.$store.getters['customs/list'];
        },
        goodsData() {
            return this.customsList.find(item => item.id == this.$route.params.id)?.goods;
        },
    },

    methods: {
        doSort(a, b) {
            if (!this.sort.order) {
                return 0; 
            }

            if (this.sort.order === 1) {
                return a[this.sort.key] > b[this.sort.key] ? 1 : -1;
            }

            return a[this.sort.key] > b[this.sort.key] ? -1 : 1;
        },
        updateSort(colId) {
            if (colId !== this.sort.key) {
                this.sort.key = colId;
                this.sort.order = 1;

                return;
            }

            if (this.sort.order === 1) {
                this.sort.order = -1;

                return;
            }

            if (this.sort.order === -1) {
                this.sort.order = 0;

                return;
            }

            this.sort.order = 1;
        },
        toggleSort(colId) {
            this.updateSort(colId);
            this.goodsData = [...list].sort(this.doSort);
        },
    },
}
</script>
<style>
.customs-card__goods-th {
    padding-left: 24px;
    position: relative;
    display: flex;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 22px;
        transform: translateY(-50%);
        background-color: #B1BFCA;
    }

    &:first-child {
        padding-left: 0;
        border-left: none;

        &:before {
            content: none;
        }
    }

    &:first-child {
        flex-grow: 1;
    }
}

.customs-card__goods-tr {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
}

.customs-card__goods-td {
    padding-left: 24px;

    &:first-child {
        padding-left: 0;
    }

    &:first-child {
        flex-grow: 1;
    }
}
</style>