<template>
    <div class="customs-card__payments" style="width: 294px;">
        <span class="customs-card__title">
            Customs payments
        </span>
        <div class="customs-card__payments-list">
            <div class="customs-card__payments-item">
                <span>Fees:</span>
                <span class="f-w-medium">0</span>
            </div>
            <div class="customs-card__payments-item">
                <span>Excise taxes:</span>
                <span class="f-w-medium">0</span>
            </div>
            <div class="customs-card__payments-item">
                <span>VAT:</span>
                <span class="f-w-medium">12%</span>
            </div>
            <div class="customs-card__payments-item">
                <span>Customs duties:</span>
                <span class="f-w-medium">0</span>
            </div>
            <div class="customs-card__payments-item">
                <span>Total:</span>
                <span class="f-w-medium">0</span>
            </div>
        </div>

        <div class="customs-card__delimeter" />

        <div>
            History of changes

            <div class="sh-d-progress">
                <TimeLineItem status="Arrived" date="15:25 Sep-03-2024" />
                <TimeLineItem status="At broker" date="16:01 Sep-03-2024" />
                <TimeLineItem status="Released" date="00:00 Sep-04-2024" />
            </div>
        </div>
    </div>
</template>
<script>
import TimeLineItem from '@/components/customs/timelineItem.vue';

export default {
    components: {
        TimeLineItem,
    },
}
</script>